/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';

const appOidcClientId = process.env.REACT_APP_WELLVOLUTION_CLIENT_ID || '';
const appOidcClientSecret = process.env.REACT_APP_WELLVOLUTION_CLIENT_SECRET || '';
const appTokenUrl = process.env.REACT_APP_WELLVOLUTION_URL || '';
const appSoleraProviderUrl = process.env.REACT_APP_SOLERA_PROVIDER_URL || '';

export const getTokenInfoWellvolution = async (): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: ' ',
    },
  };

  const clientId = appOidcClientId;
  const clientSecret = appOidcClientSecret;
  const grantType = 'client_credentials';
  const audience = 'wavePartnerAPI';

  const params = new URLSearchParams();
  params.append('grant_type', grantType);
  params.append('client_id', clientId);
  params.append('client_secret', clientSecret);
  params.append('audience', audience);

  return await axios.post(`${appTokenUrl}/oauth/token`, params, config);
};

export const getUserInfoWellvolution = async (
  accessToken: string,
  userId: string,
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const urlLink = `${appSoleraProviderUrl}/v2/userinfo/${userId}`;

  return await axios.get(urlLink, config);
};
