export enum GeneralPaths {
  LandingPage = '/landing',
  AuthenticationPage = '/check',
  UserSearchPage = '/user-search',
  LogOutPage = '/logout',
  HomePage = '/',
  CommunityPage = '/community',
  LogsPage = '/logs',
  NourishPage = '/nourish',
  ProfilePage = '/profile',
  ChatPage = '/chat',
  UnsuccessfulLoginPage = '/unsuccessful',
  OnBoardingPage = '/on-boarding/type/:typeId',
  OnBoardingSchedulePage = '/on-boarding/schedule',
  IntercomPage = '/intercom',
  MovementPage = '/movement',
  MovementTypePage = '/movement/:movementType',
  MovementWorkoutGroupPage = '/movement/:movementType/:workoutGroupSlug',
  MovementWorkoutPage = '/movement/:movementType/:workoutGroupSlug/:workoutSlug',
  SponsoredUserOnboardingPage = '/onboarding',
  ReEnrollmentPage = '/reenroll/:userId',
  // EligibilityPage = '/eligibility',
  // Eligibility= '/newEligibility',
  EligibilityPage = '/newEligibility',
  Eligibility= '/eligibility',
  Terms = '/terms',
  HLQ= '/hlq',
  Privacy = '/privacy',

  ReferralPage = '/referral',
  OnBoardingReferralPage = '/on-boarding/referral',
  MealplannerPage = '/mealplanner',
}

export enum AdminPaths {
  HomePage = '/admin',
  UserProfilePage = '/admin/user-profile/:userId',
}

export enum WeightType {
  LBS = 'lbs',
  KG = 'kg',
}

export enum HeightType {
  FT = 'feet',
  IN = 'inches',
  CM = 'cm',
}

export enum MealType {
  BREAKFAST = 'Breakfast',
  SNACK = 'Snack',
  LUNCH = 'Lunch',
  LATE_SNACK = 'LateSnack',
  DINNER = 'Dinner',
  OTHER = 'Other',
}

export enum WorkoutIntensity {
  EASY = 'Easy',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum BodyMeasurementType {
  IN = 'in',
  CM = 'cm',
}

export enum MembershipType {
  ALL_MEMBERS = 'All members',
  COACHES = 'Coaches',
  MEMBERS_WITHOUT_COACHING = 'Members without coaching',
}

//real constants
export const MOBILE_SIZE = 600;
export const TABLET_SMALL_SIZE = 905;
export const TABLET_BIG_SIZE = 1240;

export enum Devices {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const STRING_INCH = 'inch';

export const WEIGHT = 'weight';
export const FOOD = 'food';
export const WATER = 'water';
export const SLEEP = 'sleep';
export const ACTIVITY = 'activity';
export const STEPS = 'steps';
export const BOWEL_MOVEMENTS = 'bowelMovements';
export const STRESS = 'stress';
export const GLUCOSE = 'bloodGlucose';

export const TODAY = 'today';
export const BEFORE1 = 'before1';
export const BEFORE2 = 'before2';
export const BEFORE3 = 'before3';

export const SELFPAY = 'Self-Pay';
export const INSURANCE = 'Insurance';

export const SELECT = 'Select';

export const CATEGORY_CHIP_TAG_ID = 'category-chip-tags-with-box-id';

export enum SubscriptionType {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
}

export const EMPTY_FIELD_PLACEHOLDER = '/';

export const EMPTY_TIME_PLACEHOLDER = '--';

export const EMPTY_IMAGE = '#';
export const INITIAL_EMPTY_VALUE = 0;
export const SCROLL_MARGIN = '80';

export const FULL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const AM_PM_FORMAT = 'MM-DD-YYYY hh:mm A';
export const ONLY_DATE_FORMAT = 'MM-DD-YYYY';
export const ONLY_DAY_AND_MONTH = 'MM/DD';
export const TIME_FORMAT = 'hh:mm A';
export const DATE_PICKER_DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_LONGER_FORMAT = 'MMMM dd yyyy';

export const INITIAL_DATABASE_DATE = '0001-01-01';

export const DEFAULT_PER_PAGE_SIZE = 10;
export const DEFAULT_PAGE_START = 1;

export const NOTES_PAGE_SIZE = 3;

export const MAX_CHARACTERS = 255;
export const MAX_INT_VALUE = 999;
export const MAX_INT_STEPS_VALUE = 100000;
export const MAX_INT_WEIGHT_VALUE_IN = 12;
export const MAX_INT_WEIGHT_VALUE_CM = 99;
export const MAX_INT_WEIGHT_VALUE_M = 2;
export const MAX_INT_WEIGHT_VALUE_FEET = 9;

export const GROUP_TAGS = 'Group Tags';

export const SUBSCRIPTION_EXPIRES_SOON = 5;

export const level1 = 'Level 1';
export const level2 = 'Level 2';
export const nextLevel = 'Next Level';
export const prepPhase = 'Prep-Phase';
export const levelOne = 'Level One';
export const levelTwo = 'Level Two';
export const levelThree = 'Level Three';
export const faq = 'FAQ';
export const prepPhase2 = 'Prep Phase';
export const blog = 'Blog';

export const REGISTER_LUNCH_AND_LEARN_LINK_12PM =
  'https://us06web.zoom.us/meeting/register/tZ0vcuGgqT8rHNPNv7xsOY5tJTFZAauEVOPz';
export const REGISTER_LUNCH_AND_LEARN_LINK_3PM =
  'https://us06web.zoom.us/meeting/register/tZ0vcuGgqT8rHNPNv7xsOY5tJTFZAauEVOPz';
export const REGISTER_KICK_OFF_CALL_LINK =
  'https://us06web.zoom.us/meeting/register/tJYvcOqrpzIvE9YMZegDFdcuRWcg7_XF0dOJ';
export const REGISTER_LIVE_LINK =
  'https://us06web.zoom.us/meeting/register/tJUvceutrDkjEtWFgKFkKytaJgUEKAHkMm9H';
export const AQUITI_LINK = 'https://aquiti.com';

export const PRIVACY_POLICY = 'https://betrhealth.com/privacy-policy/';

export const TERMS_AND_CONDITIONS = 'https://betrhealth.com/terms-of-service/';

export const ANDROID_APP = 'https://play.google.com/store/apps/details?id=com.betrhealth.app';
export const IOS_APP = 'https://apps.apple.com/rs/app/betr/id1598906964';

// chat based constants
export const EMOTICON_TYPE_KEY = 'emoticonType';

// giphy categories
export const GIPHY_TRENDING = 'TRENDING';
export const GIPHY_CELEBRATE = 'CELEBRATE';
export const GIPHY_HEALTHY = 'HEALTHY';
export const GIPHY_WORKOUT = 'WORKOUT';

// meta files types
export const METAFILE_GIF = 'GIF';

export const BETR_CHAT_MEMBER = 'betrhealth';

export const PURCHASE_COACHING_LINK = 'https://store.betrhealth.com/collections/coaching';

export const DEBOUNCE_TIME = 500;

export const CHOLESTEROL = 'Cholesterol';
export const A1C = 'A1C';
