/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as usersActionTypes from './users-types';
import {IUser} from '../../../models/IUser';
import {
  editUserSkinTone,
  getAllUsers,
  getSearchPaginationUsers,
  getTokenInfoMiniOrange,
  getUserInfo,
  getUser,
  setFirebase,
  getTokenInfoMiniOrangeLocal,
  deleteUnreadCountDatabase,
  updateOnboardUserApi,
  reEnrollGetApi,
  updateReEnrollUserApi,
  // getSignedCookie,
  updateOnboardStatusUserApi,
} from '../../../api/users.api';
import {Dispatch} from 'redux';
import {
  clearLocaleStorage,
  getUserData,
  setTokenInfo,
  setUserInfo,
} from '../../../utils/locale-storage-service';
import history from '../../../history';
import {ITokenInfo} from '../../../models/ITokenInfo';
import {IUserDto} from '../../../dtos/IUserDto';
import {getUserHomePath} from '../../../routes';
import {IUserSearch} from '../../../models/IUserSearch';
import {IUserPaginationFilter} from '../../../models/IUserPaginationFilter';
import {IUserPaginationResult} from '../../../models/IUserPaginationResult';
import {addEngagementPointDatabase} from '../../../api/engagement.api';
import {EngagementPointType} from '../../../utils/enums';
import {GeneralPaths} from '../../../utils/constants';
import {EmojiSkin} from 'emoji-mart';

export interface ILogin {
  type: typeof usersActionTypes.LOGIN;
  payload: IUser | null;
}
export interface IStatusError {
  type: typeof usersActionTypes.STATUS_ERROR;
  payload: any | null;
}

export interface ILogout {
  type: typeof usersActionTypes.LOGOUT;
  payload: null;
}

export interface ISaveSkinTone {
  type: typeof usersActionTypes.SAVE_SKIN_TONE;
  payload: EmojiSkin | undefined;
}

export interface IStoreUsers {
  type: typeof usersActionTypes.STORE_USERS;
  payload: any;
}

export interface IStoreSecondaryUsers {
  type: typeof usersActionTypes.STORE_SECONDARY_USERS;
  payload: any;
}

export interface IStoreCoachMembers {
  type: typeof usersActionTypes.STORE_COACH_MEMBERS;
  payload: any;
}

export interface IStoreSupercoachFilteredUsers {
  type: typeof usersActionTypes.STORE_SUPERCOACH_FILTERED_USERS;
  payload: any;
}

export interface IStorePaginationUsers {
  type: typeof usersActionTypes.STORE_PAGINATION_USERS;
  payload: any;
}

export interface IClearPaginationUsers {
  type: typeof usersActionTypes.CLEAR_PAGINATION_USERS;
  payload: any;
}

export interface ISelectUser {
  type: typeof usersActionTypes.SELECT_USER;
  payload: IUser | null;
}
export interface IUpdateUnread {
  type: typeof usersActionTypes.UPDATE_UNREAD_STATE;
  payload: string | null;
}

export const saveUserData = (data: IUser | null): ILogin => {
  return {
    type: usersActionTypes.LOGIN,
    payload: data,
  };
};
export const saveErrorStatus = (data: any | null): IStatusError => {
  return {
    type: usersActionTypes.STATUS_ERROR,
    payload: data,
  };
};

export const logOutSuccess = (): ILogout => {
  return {
    type: usersActionTypes.LOGOUT,
    payload: null,
  };
};

export const storeUsers = (data: IUser[]): IStoreUsers => {
  return {
    type: usersActionTypes.STORE_USERS,
    payload: data,
  };
};

export const storeSecondaryUsers = (data: IUser[]): IStoreSecondaryUsers => {
  return {
    type: usersActionTypes.STORE_SECONDARY_USERS,
    payload: data,
  };
};

export const storeSuperCoachFilteredUsers = (data: IUser[]): IStoreSupercoachFilteredUsers => {
  return {
    type: usersActionTypes.STORE_SUPERCOACH_FILTERED_USERS,
    payload: data,
  };
};

export const storeCoachMembers = (data: IUser[]): IStoreCoachMembers => {
  return {
    type: usersActionTypes.STORE_COACH_MEMBERS,
    payload: data,
  };
};

export const storePaginationUsers = (data: IUserPaginationResult): IStorePaginationUsers => {
  return {
    type: usersActionTypes.STORE_PAGINATION_USERS,
    payload: data,
  };
};

export const clearPaginationUsers = (): IClearPaginationUsers => {
  return {
    type: usersActionTypes.CLEAR_PAGINATION_USERS,
    payload: {
      usersPaginatedList: [],
      pageNumber: 0,
      totalPages: 0,
      pagesize: 0,
      totalCount: 0,
    },
  };
};
export const saveUserObject = (userInfo: IUserDto): IUser => {
  let tempRoles: string[] = [];
  if (userInfo.roles) {
    tempRoles = userInfo.roles.map((oneRole) => oneRole.name);
  }
  const user: IUser = {
    id: userInfo.id,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    externalId: userInfo.externalId,
    roles: tempRoles,
    address: userInfo.address,
    isActive: userInfo.isActive,
    hasCoaching: userInfo.hasCoaching,
    phaseId: userInfo.phaseId,
    phaseStartDate: userInfo.phaseStartDate,
    tags: userInfo.tags,
    measurementUnit: userInfo.measurementUnit,
    height: userInfo.height,
    initialWeight: userInfo.initialWeight,
    dateOfBirth: userInfo.dateOfBirth,
    memberSubscription: userInfo.memberSubscription,
    coachingSubscription: userInfo.coachingSubscription,
    imageUrl: userInfo.imageUrl,
    gender: userInfo.gender,
    created: userInfo.created,
    trainer: userInfo.trainer ? saveUserObject(userInfo.trainer) : null,
    phoneNumber: userInfo.phoneNumber,
    comment: userInfo.comment,
    credentials: userInfo.credentials,
    secondaryTrainer: userInfo.secondaryTrainer,
    skinTone: userInfo.skinTone,
    conversationId: userInfo.conversationId,
    unReadCount: userInfo.unReadCount,
    phoneStatus: userInfo.phoneStatus,
    onBoardingStatus: userInfo.onBoardingStatus,
  };
  return user;
};
export const logIn = (code: string, state: any): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await setFirebase();
    let responseMiniOrange: any = '';
    let data: any = null;
    if (code && !state) {
      responseMiniOrange = await getTokenInfoMiniOrangeLocal(code);
      data = responseMiniOrange;
    } else {
      responseMiniOrange = await getTokenInfoMiniOrange(code);
      data = responseMiniOrange.data;
    }
    const tokenInfo: ITokenInfo = data;
    setTokenInfo(tokenInfo);
    try {
      const response = await getUserInfo();
      const userInfo: IUserDto = response;
      if (userInfo?.onBoardingStatus === 1) {
        window.location.href = `https://betrhealth.com/solera?userid=${response?.id}`;
      }
      const user: IUser = await saveUserObject(userInfo);
     
      setUserInfo(user);
      await dispatch(saveUserData(user));
      await addEngagementPointDatabase({eventType: EngagementPointType.LOGIN, description: ''});
      if (userInfo?.onBoardingStatus !== 1) {
         history.push(getUserHomePath(user));
      }
    } catch (err) {
      history.push(GeneralPaths.UnsuccessfulLoginPage);
    }
  };
};

export const logOut = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    clearLocaleStorage();
    dispatch(logOutSuccess());
  };
};

export const saveSkinTone = (skinTone: EmojiSkin): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await editUserSkinTone(skinTone);
    const user = getUserData();
    user.skinTone = skinTone;
    setUserInfo(user);
    dispatch({type: usersActionTypes.SAVE_SKIN_TONE, payload: skinTone});
  };
};

export const validateUserSession = (
  callback: (loading: boolean) => void,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const userInfo = getUserData();

    if (userInfo.email) {
      dispatch(saveUserData(userInfo));
    }

    callback(false);
  };
};

export const searchAllUsers = (
  data: IUserSearch,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const users: IUserDto[] = await getAllUsers(data);
    const modifiedUsers: IUser[] = [];
    users.forEach((user) => modifiedUsers.push(saveUserObject(user)));
    if (data?.includeSecondaryTrainees === true) {
      dispatch(storeSecondaryUsers(modifiedUsers));
    } else {
      dispatch(storeUsers(modifiedUsers));
    }
  };
};

export const searchSupercoachUsers = (
  data: IUserSearch,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const users: IUserDto[] = await getAllUsers(data);
    const modifiedUsers: IUser[] = [];
    users.forEach((user) => modifiedUsers.push(saveUserObject(user)));
    dispatch(storeSuperCoachFilteredUsers(modifiedUsers));
  };
};

export const searchCoachUsers = (
  data: IUserSearch,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const users: IUserDto[] = await getAllUsers(data);
    const modifiedUsers: IUser[] = [];
    users.forEach((user) => modifiedUsers.push(saveUserObject(user)));
    dispatch(storeCoachMembers(modifiedUsers));
  };
};

export const searchUsersWithPagination = (
  data: IUserPaginationFilter,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const paginationUsers: IUserPaginationResult = await getSearchPaginationUsers(data);
    dispatch(storePaginationUsers(paginationUsers));
  };
};

export const selectUserSuperCoach = (data: IUser | null): ISelectUser => {
  return {
    type: usersActionTypes.SELECT_USER,
    payload: data,
  };
};

export const getUserById = async (userInfo: string): Promise<IUser> => {
  const user: IUserDto = await getUser(userInfo);

  return saveUserObject(user);
};

export const getCurrentUser = (): (() => Promise<void>) => {
  return async () => {
    const user: IUserDto = await getUserInfo();
    const userForRedux: IUser = saveUserObject(user);
    setUserInfo(userForRedux);
    saveUserData(userForRedux);
  };
};

export const deleteUnreadCount = (id: string): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteUnreadCountDatabase(id);
    await dispatch({
      type: usersActionTypes.UPDATE_UNREAD_STATE,
      payload: id,
    });
  };
};

export const updateOnboardUser = (data: any): ((dispatch: Dispatch) => Promise<void>) => {
  return async () => {
    return await updateOnboardUserApi(data);
  };
};
// export const updateDirectOnboardUser = (data: any): ((dispatch: Dispatch) => Promise<void>) => {
//   return async () => {
//       return await updateDirectOnboardUser(data)
//   };
// };
// export const getDirectOnboardUser = (data: any): ((dispatch: Dispatch) => Promise<void>) => {
//   return async () => {
//       return await getDirectOnboardUser(data)
//   };
// };
export const updateOnboardUserStatus = (data: any): ((dispatch: Dispatch) => Promise<void>) => {
  return async () => {
    return await updateOnboardStatusUserApi(data);
  };
};
export const reEnrollGet = async (userId: any): Promise<void> => {
  return await reEnrollGetApi(userId);
};

export const updateReEnrollUser = (data: any): ((dispatch: Dispatch) => Promise<void>) => {
  return async () => {
    return await updateReEnrollUserApi(data);
  };
};
