import {getTheme} from '../theme/theme';
import React from 'react';
import {level1, level2, nextLevel} from './constants';

export const getColorForLevel = (level: string): React.CSSProperties['color'] => {
  if (level === level1) {
    return getTheme().palette.newTagColours.teaGreen;
  }

  if (level === level2) {
    return getTheme().palette.newTagColours.magicMint;
  }

  if (level === nextLevel) {
    return getTheme().palette.newTagColours.magicMint;
  }

  return undefined;
};

export const getHoverColorForLevel = (level: string): React.CSSProperties['color'] => {
  if (level === level1) {
    return getTheme().palette.newTagColours.grannySmithApple;
  }

  if (level === level2) {
    return getTheme().palette.newTagColours.turquoiseGreen;
  }

  if (level === nextLevel) {
    return getTheme().palette.newTagColours.turquoiseGreen;
  }

  return undefined;
};
