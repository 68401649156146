import {Route} from 'react-router-dom';
import Home from './pages/general/home/Home';
import AdminHomePage from './pages/admin/home/HomePage';
import AdminUserProfilePage from './pages/admin/user-profile/UserProfilePage';
import {AdminPaths, GeneralPaths} from './utils/constants';
import LandingPage from './pages/general/landing-page/LandingPage';
import ProfilePage from './pages/general/profile/ProfilePage';
import AuthenticationPage from './pages/authentification/AuthenticationPage';
import {IUser} from './models/IUser';
import NourishPage from './pages/general/nourish-page/NourishPage';
import LogsPage from './pages/general/logs-page/LogsPage';
import CommunityPage from './pages/general/community/CommunityPage';
import {getAdminRoles, getAllRoles, getGeneralRoles} from './utils/role-helper';
import {RoleType} from './utils/enums';
import LogOutPage from './pages/authentification/LogOutPage';
import UserSearchPage from './pages/general/user-search/UserSearchPage';
import UnsuccessfulLoginPage from './pages/general/unsuccessful-login-page/UnsuccessfulLoginPage';
import OnBoardingPage from './pages/general/onboarding/OnBoardingPage';
import OnBoardingSchedulePage from './pages/general/onboarding/OnBoardingScheduleCallPage';
import IntercomPage from './pages/general/IntercomPage';
import SponsoredUserOnboardingPage from './pages/general/sponsoredUserOnboardingPage/SponsoredUserOnboardingPage';
import ReEnrollmentPage from './pages/general/ReEnrollmentPage/ReEnrollmentPage';
import EligibilityPage from './pages/general/eligibility-page/EligibilityPage';
import MealplannerPage from './pages/general/MealplannerPage';
import Terms from './pages/general/landing-page/Terms';
import Privacy from './pages/general/landing-page/Privacy';
import Eligibility from './pages/general/eligibility-page/Eligibility';
import HlqLandboat from './pages/general/HlqLandboat';

interface IRoute {
  path: string;
  component: any;
  needAuth: boolean;
  allowedRoles?: string[];
}

interface IRoutes {
  [key: string]: IRoute;
}

const GENERAL_ROUTES: IRoutes = {
  LandingPage: {
    path: GeneralPaths.LandingPage,
    component: <LandingPage />,
    needAuth: false,
    allowedRoles: getGeneralRoles(),
  },
  AuthenticationPage: {
    path: GeneralPaths.AuthenticationPage,
    component: <AuthenticationPage />,
    needAuth: false,
  },
  UserSearchPage: {
    path: GeneralPaths.UserSearchPage,
    component: <UserSearchPage />,
    needAuth: false,
    allowedRoles: getGeneralRoles(),
  },
  LogOutPage: {
    path: GeneralPaths.LogOutPage,
    component: <LogOutPage />,
    needAuth: true,
  },
  HomePage: {
    path: GeneralPaths.HomePage,
    component: <Home />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  LogsPage: {
    path: GeneralPaths.LogsPage,
    component: <LogsPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  NourishPage: {
    path: GeneralPaths.NourishPage,
    component: <NourishPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  MovementPage: {
    path: GeneralPaths.MovementPage,
    component: <NourishPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  // MovementFitnessPage: {
  //   path: `${GeneralPaths.MovementPage}/fitness`,
  //   component: <NourishPage />,
  //   needAuth: true,
  //   allowedRoles: getGeneralRoles(),
  // },
  // MovementRehabPage: {
  //   path: `${GeneralPaths.MovementPage}/rehab`,
  //   component: <NourishPage />,
  //   needAuth: true,
  //   allowedRoles: getGeneralRoles(),
  // },
  MovementTypePage: {
    path: GeneralPaths.MovementTypePage,
    component: <NourishPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  MovementWorkoutGroupPage: {
    path: GeneralPaths.MovementWorkoutGroupPage,
    component: <NourishPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  MovementWorkoutPage: {
    path: GeneralPaths.MovementWorkoutPage,
    component: <NourishPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  ProfilePage: {
    path: GeneralPaths.ProfilePage,
    component: <ProfilePage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  CommunityPage: {
    path: GeneralPaths.CommunityPage,
    component: <CommunityPage />,
    needAuth: true,
    allowedRoles: getGeneralRoles(),
  },
  UnsuccessfulLoginPage: {
    path: GeneralPaths.UnsuccessfulLoginPage,
    component: <UnsuccessfulLoginPage />,
    needAuth: true,
    allowedRoles: getAllRoles(),
  },
  OnBoardingPage: {
    path: GeneralPaths.OnBoardingPage,
    component: <OnBoardingPage />,
    needAuth: false,
  },
  SponsoredUserOnboardingPage: {
    path: GeneralPaths.SponsoredUserOnboardingPage,
    component: <SponsoredUserOnboardingPage />,
    needAuth: true,
  },
  TermsPage: {
    path: GeneralPaths.Terms,
    component: <Terms />,
    needAuth: false,
    allowedRoles: getGeneralRoles(),
  },
  PrivacyPage: {
    path: GeneralPaths.Privacy,
    component: <Privacy />,
    needAuth: false,
    allowedRoles: getGeneralRoles(),
  },
  ReEnrollmentPage: {
    path: GeneralPaths.ReEnrollmentPage,
    component: <ReEnrollmentPage />,
    needAuth: false,
  },
  EligibilityPage: {
    path: GeneralPaths.EligibilityPage,
    component: <EligibilityPage />,
    needAuth: false,
  },
  Eligibility: {
    path: GeneralPaths.Eligibility,
    component: <Eligibility />,
    needAuth: false,
  },
  HLQ: {
    path: GeneralPaths.HLQ,
    component: <HlqLandboat/>,
    needAuth: true,
  },
  IntercomPage: {
    path: GeneralPaths.IntercomPage,
    component: <IntercomPage />,
    needAuth: false,
  },
  OnBoardingSchedulePage: {
    path: GeneralPaths.OnBoardingSchedulePage,
    component: <OnBoardingSchedulePage />,
    needAuth: false,
  },
  mealplannerPage: {
    path: GeneralPaths.MealplannerPage,
    component: <MealplannerPage />,
    needAuth: true,
  },
};

const ADMIN_ROUTES: IRoutes = {
  AdminHomePage: {
    path: AdminPaths.HomePage,
    component: <AdminHomePage />,
    needAuth: true,
    allowedRoles: getAdminRoles(),
  },
  AdminUserProfilePage: {
    path: AdminPaths.UserProfilePage,
    component: <AdminUserProfilePage />,
    needAuth: true,
    allowedRoles: getAdminRoles(),
  },
};

const ROUTES = {
  ...GENERAL_ROUTES,
  ...ADMIN_ROUTES,
};

export function checkWildcardRoute(value: string, path: string): boolean {
  const regex = value.replace(/:[a-zA-Z0-9]+/g, '[^ ]*');
  const match = path.match(regex);

  return !!(match && match?.length === 1 && match[0] === path);
}

export function getRoute(path: string): IRoute | null {
  for (const [, value] of Object.entries(ROUTES)) {
    if (value.path === path) {
      return value;
    }

    if (checkWildcardRoute(value.path, path)) {
      return value;
    }
  }

  return null;
}

export function getRoutes(): any {
  return Object.keys(ROUTES).map((key: string) => {
    const routeKey = `route-${key}-${ROUTES[key].path}`;
    return (
      <Route key={routeKey} exact path={ROUTES[key].path} component={() => ROUTES[key].component} />
    );
  });
}

export function checkIfPathNeedAuth(path: string): boolean {
  const pathObject = getRoute(path);

  return !pathObject || pathObject.needAuth;
}

export function checkIfRoleIsFulfilled(path: string, currentUser: IUser): boolean {
  const pathObject = getRoute(path);

  if (!pathObject) {
    return true;
  }

  if (!pathObject.allowedRoles) {
    return false;
  }


  if (currentUser?.roles == null || currentUser?.roles?.length == 0) {
    return false;
  }

  const appropriateRoles: string[] = currentUser.roles.filter((oneRole) =>
    pathObject?.allowedRoles?.includes(oneRole),
  );

  return !(appropriateRoles?.length > 0);
}

export const getUserHomePath = (currentUser: IUser): string => {
  // console.log("currentUser--", currentUser)
  if(currentUser?.onBoardingStatus === 1){
    return   window.location.href = `https://betrhealth.com/solera?userid=${currentUser?.id}`
  }else{
    return  currentUser?.roles?.length && currentUser?.roles.includes(RoleType.ADMIN)
    ? AdminPaths.HomePage
    : GeneralPaths.HomePage;
  }
  //  return GeneralPaths.LandingPage
  // return currentUser?.onBoardingStatus === 1 ? GeneralPaths.SponsoredUserOnboardingPage : currentUser?.roles?.length && currentUser?.roles.includes(RoleType.ADMIN)
  //   ? AdminPaths.HomePage
  //   : GeneralPaths.HomePage;
};

export default ROUTES;
