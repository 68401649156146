import React from 'react';
import {useTranslation} from 'react-i18next';
import Reminder from './Reminder';
import {ReminderType} from '../../../utils/enums';
import NotificationReminder from '../../../assets/icons/reminder/notification-reminder-icon.svg';
import AppointmentReminder from '../../../assets/icons/reminder/appoinment-reminder-icon.svg';
import {useTheme} from '@material-ui/styles';
import {Theme} from '@material-ui/core';
import {INotificationDto} from '../../../dtos/INotificationDto';
import history from '../../../history';
import {GeneralPaths} from '../../../utils/constants';
import FriendlyReminderResolver from './FriendlyReminderResolver';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {useSelector} from 'react-redux';
import {selectNotifications} from '../../../store/selectors/homepage.selectors';
import {getTokenData} from '../../../utils/locale-storage-service';

const ReminderResolver: React.FC = () => {
  const [t] = useTranslation();
  const theme = useTheme<Theme>();
  const currentUser = useSelector(selectCurrentUser);
  const loadedNotifications = useSelector(selectNotifications);
  const tokenData = getTokenData();
  const token = tokenData.idToken;
  const jwtUrl = `${process.env.REACT_APP_JWT_URL}/${token}?relay=`;
  const handleClick = (notification: INotificationDto) => {
    if (notification.category === ReminderType.FRIENDLY) {
      history.push(GeneralPaths.LogsPage);
    } else {
      window.open(`${jwtUrl}${process.env.REACT_APP_SHOPIFY_LINK}`, '_blank');
    }
  };

  const showNotifications = (notification: INotificationDto) => {
    switch (notification.category) {
      case ReminderType.APPOINTMENT:
        return (
          <Reminder
            title={t('Homepage.notificationPanel.logWeight.title')}
            titleIcon={AppointmentReminder}
            content={t('Homepage.notificationPanel.logWeight.content')}
            buttonText={t('Homepage.notificationPanel.logWeight.buttonText')}
            handleClick={() => handleClick(notification)}
            color={theme.palette.background.paper}
            reminderType={ReminderType.APPOINTMENT}
          />
        );
      case ReminderType.FRIENDLY:
        return <FriendlyReminderResolver notification={notification} />;
      case ReminderType.SUBSCRIPTION:
        return (
          <Reminder
            title={t('Homepage.notificationPanel.upgrade.title')}
            titleIcon={NotificationReminder}
            content={`${t('Homepage.notificationPanel.greeting')} 
            ${currentUser.firstName}, ${t('Homepage.notificationPanel.upgrade.content')}`}
            buttonText={t('Homepage.notificationPanel.upgrade.buttonText')}
            handleClick={() => handleClick(notification)}
            color={theme.palette.background.paper}
            reminderType={ReminderType.SUBSCRIPTION}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      {loadedNotifications.map((notification) => (
        <div key={notification.value}>{showNotifications(notification)}</div>
      ))}
    </React.Fragment>
  );
};

export default ReminderResolver;
