import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ExpandIcon} from './ExpandIcon';
import {SideMenuBarItem} from './SideMenuBarItem';
import {ExpandableContainer} from '../expandable-container/ExpandableContainer';
import Loader from '../loader/Loader';
import {FilterOptions} from './FilterOptions';
import {ConnectionsGroup} from './ConnectionsGroup';
import {SearchBox} from '../search-box/SearchBox';
import {IConversations} from '../../../models/IConversations';
import {SideMenuSelectedType} from '../../../utils/enums';
import {ReactComponent as ConnectionsIcon} from '../../../assets/icons/side-menu-bar/customers-icon.svg';
import {
  selectBackendConversations,
  // selectConversations,
  selectLoading,
  selectMember,
  selectPhases,
} from '../../../store/selectors/homepage.selectors';
import {selectUserTags} from '../../../store/selectors/loggingPageSelector';
import {selectAllUsers, selectCurrentUser, selectAllSecondaryUsers} from '../../../store/selectors/users.selectors';
import {ICasualTag} from '../../../models/ITag';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';
import {IConversationDto} from '../../../dtos/IConversationDto';
import ClientsSwitch from './ClientsSwitch';
import * as filterActions from '../../../store/actions/filters/filter-actions';

import useStyles from './connections.styles';
import {getDateFromDate} from '../../../utils/date-time-utils';
// import { getAllUsers } from '../../../api/users.api';

interface Props {
  onClick: (value: SideMenuSelectedType) => void;
  selectedSideMenuItem: SideMenuSelectedType;
  setOpenSideMenuBar?: (e: boolean) => void;
}

export const Connections: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);

  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const [connectionsClicked, setConnectionsClicked] = useState(false);
  // const [selectedConnection, setSelectedConnection] = useState('');
  const [t] = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const loadedPhases = useSelector(selectPhases);
  const allUsers = useSelector(selectAllUsers);
  const allSecondaryUsers = useSelector(selectAllSecondaryUsers);
  const [users, setUsers] = useState(allUsers);
  const [phases, setPhases] = useState<any>([{key: 0, value: ''}]);
  // const conversations = useSelector((state) => selectConversations(state));
  const conversationsBackend = useSelector(selectBackendConversations);
  const [tagsList, setTagsList] = useState<IUserCategoryTagDto[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<IConversations[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(true);
  const isLoadingRedux = useSelector(selectLoading);
  const selectedMemberFromRedux = useSelector(selectMember);
  const [secondaryTrainees, setSecondaryTrainees] = useState(false);
  const [filters, setFilters] = useState({
    phaseObject: {
      key: 0,
      value: '',
    },
    date: null,
    searchCriteria: '',
  });

  const initialUserTagsList = useSelector(selectUserTags);
  const selectedStateTags = useSelector( (state: any) => state?.filters?.selectedFilters) || [];
  const tagsBoxRef: any = useRef();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setSwitchLoading(true);
      initialUserTagsList.forEach((tags) => {
        tags.tags.forEach((tag) => {
          const isSelectedTag = (selectedStateTags?.tags ? selectedStateTags?.tags?.findIndex((tagID: any) => tagID === tag?.id) === false : false); 
          tag.isVisible = isSelectedTag ? isSelectedTag : false;
        });
      });
      // console.log('initialUserTagsList-', initialUserTagsList)

      setPhases(loadedPhases);
      setFilteredConversations(conversationsBackend);
       setTagsList(initialUserTagsList);
      setLoading(false);
      setSwitchLoading(false);
      setUsers(allUsers);
    };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleFilter('', false ,'', true);
    loadData();
  }, [ initialUserTagsList, allUsers, loadedPhases, conversationsBackend]);

  useEffect(() => {
    const loadData = async () => {
      setSwitchLoading(true);
      initialUserTagsList.forEach((tags) => {
        tags.tags.forEach((tag) => {
          const isSelectedTag = (selectedStateTags?.tags ? selectedStateTags?.tags?.findIndex((tagID: any) => tagID === tag?.id) === -1 : -1); 
          tag.isVisible = isSelectedTag ? true : false;
        });
      });
      
      setTagsList(initialUserTagsList);
      setSwitchLoading(false);  
    };
    loadData();
  }, [initialUserTagsList, selectedStateTags?.tags]);

  useEffect(() => {
    // selectedStateTags && setFilters(selectedStateTags)
    const filterSetting: any = {
    phaseObject: {
      key: 0,
      value: '',
    },
    date: null,
  }
  if(selectedStateTags?.phaseId) {
    const findPhase = loadedPhases.find((phase: any) => phase.id == selectedStateTags?.phaseId)
    if(findPhase){
      filterSetting.phaseObject = {
        key: findPhase.id,
        value: findPhase.name
      }
    }
  }
  if(selectedStateTags?.phaseStartDate) {
    filterSetting.date = selectedStateTags?.phaseStartDate ? new Date(selectedStateTags?.phaseStartDate) : null
  }
  setFilters({
    ...filters,
    ...filterSetting
  })
  }, [loadedPhases])

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'phaseSelect') {
      setFilters({
        ...filters,
        ['phaseObject']: {key: value.key, value: value.value},
        [name]: value.key,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };
  const changeSpecificDataType = (value: any, specificType: string) => {
    setFilters({
      ...filters,
      [specificType]: value,
    });
  };

  const handleFilter = async (searchValue: any, includeSecondary?: boolean, obj?: any, load = false) => {
    const result: any = [];
    const selectedTags = tagsBoxRef?.current?.getSelectedTags().length ?
      tagsBoxRef?.current?.getSelectedTags().map((tag: ICasualTag) => tag.id) :
      load && selectedStateTags?.tags?.length ?
        selectedStateTags.tags : [];
    const formattedDate = filters.date ? getDateFromDate(filters.date) : null;
    let filterOptions: any = {
      searchFilter: searchValue,
      phaseId: load && selectedStateTags?.phaseId ?
        selectedStateTags?.phaseId :
          filters.phaseObject.key === 0 ?
            null : filters.phaseObject.key,
      phaseStartDate: formattedDate ?
        formattedDate : load && selectedStateTags?.phaseStartDate ?
          getDateFromDate(new Date(selectedStateTags?.phaseStartDate)) : null,
      tags: selectedTags,
      trainerId: currentUser.id,
      includeSecondaryTrainees:
        includeSecondary != undefined ? includeSecondary : secondaryTrainees,
    };
    // if(!isFirstCall){
      if (obj) {
        dispatch(filterActions.setSelectedFilters({
          searchFilter: searchValue,
          phaseId: null,
          phaseStartDate: null,
          tags: [],
          includeSecondaryTrainees:
        includeSecondary != undefined ? includeSecondary : secondaryTrainees,
        }));
        filterOptions = {
          searchFilter: searchValue,
          phaseId: null,
          phaseStartDate: null,
          tags: [],
          includeSecondaryTrainees:
        includeSecondary != undefined ? includeSecondary : secondaryTrainees,
        }
      } else {
        dispatch(filterActions.setSelectedFilters(filterOptions));
      }
    // } else {
    //   setIsFirstCall(false)
    // }

    setSwitchLoading(true);
    // if (obj) {
    //   obj.searchFilter = searchValue;
    //   result = await getAllUsers(obj);
    // } else {
    //   result = await getAllUsers(filterOptions);
    // }

    if(filterOptions.includeSecondaryTrainees){
      // if (!obj) {
      //     result = allSecondaryUsers;

      // }

      await allSecondaryUsers?.length > 0 &&
      allSecondaryUsers.map((data: any) => {
        const pdate = () => {
          if(filterOptions?.phaseStartDate){
            const dpdate = data?.phaseStartDate.split('-');
            const fpdate = filterOptions.phaseStartDate.split('-');
            return dpdate[0] == parseInt(fpdate[0]) && parseInt(dpdate[1]) == parseInt(fpdate[1]) && parseInt(dpdate[2].split('T')[0]) == parseInt(fpdate[2])
          }
          return true;
        }
        const isPhone = () => {
          if(!(filterOptions.searchFilter.match(/[0-9()+ ]/))){
            return false
          } {
            let phone: any = filterOptions.searchFilter.replaceAll('+', '');
            phone = phone.replaceAll(' ', '');
            phone = phone.replaceAll('(', '');
            phone = phone.replaceAll(')', '');
            phone = phone.replaceAll('-', '');
            let dPhone: any = data?.phoneNumber;
            dPhone = dPhone.replaceAll(' ', '');
            dPhone = dPhone.replaceAll('(', '');
            dPhone = dPhone.replaceAll(')', '');
            dPhone = dPhone.replaceAll('-', '');
            if(dPhone ? dPhone?.toLowerCase().includes(phone?.toLowerCase()) : ''){
              return true;
            }
          }
          return false;
        }
        const searchText = filterOptions.searchFilter?.toLowerCase().split(' ').filter((a: any) => a).join(' ');
        
        const fullName = data?.firstName ? data?.firstName?.toLowerCase() : "" + ' ' +  data?.lastName ? data?.lastName?.toLowerCase() :"";
        if(
          (
            fullName.includes(searchText) ||
            data?.email?.toLowerCase()?.includes(filterOptions?.searchFilter?.toLowerCase()) ||
            isPhone()
          ) &&
          (
            (filterOptions.phaseId ? data?.phaseId === filterOptions.phaseId : true) &&
            pdate() &&
            (filterOptions.tags?.length ? filterOptions.tags.some((item: number) => data.tags.includes(item)) : true)
          )
          ){
          result.push(data)
        }
      })

      const newArr: IConversations[] = [];
       const newConversationArray1: IConversationDto[] = [];
       const newConversationArray2: IConversationDto[] = [];
       const newConversationArray3: IConversationDto[] = [];
       const newConversationArray4: IConversationDto[] = [];
       result.map((item: any) =>{
             const itemConversation = {
               "conversationId": item?.conversationId || '',
               "conversationName": item?.id,
               "conversationSortId": undefined,
               "conversationColor": undefined,
               "phaseLevelID": item?.phaseId,
               "phaseLevel": undefined,
               "imageUrl": item?.imageUrl || null,
               "fullName": `${item?.firstName} ${item?.lastName}`,
               "nextPageUrl": null,
               "isUserActive": item?.isActive? true : false,
               "participants": []
           }
             if(item.phaseId === 1){
               newConversationArray1.push(itemConversation);
             } else if(item.phaseId === 2){
               newConversationArray2.push(itemConversation);
             } else if(item.phaseId === 3){
               newConversationArray3.push(itemConversation);
             } else if(item.phaseId === 4){
               newConversationArray4.push(itemConversation);
             }
       })
      //  newArr.push({title: 'PREP-PHASE', conversations: newConversationArray1})
      //  newArr.push({title: 'LEVEL ONE', conversations: newConversationArray2})
      //  newArr.push({title: 'LEVEL TWO', conversations: newConversationArray3})
      //  newArr.push({title: 'NEXT LEVEL', conversations: newConversationArray4})

       // console.log('result--', newArr)
       setUsers(result);
       setFilteredConversations(newArr);
    
    } else {

    await allUsers?.length > 0 &&
      allUsers.map((data: any) => {
        const pdate = () => {
          if(filterOptions?.phaseStartDate){
            const dpdate = data?.phaseStartDate.split('-');
            const fpdate = filterOptions.phaseStartDate.split('-');
            return dpdate[0] == parseInt(fpdate[0]) && parseInt(dpdate[1]) == parseInt(fpdate[1]) && parseInt(dpdate[2].split('T')[0]) == parseInt(fpdate[2])
          }
          return true;
        }
        const isPhone = () => {
          if(!(filterOptions.searchFilter.match(/[0-9()+ ]/))){
            return false
          } {
            let phone: any = filterOptions.searchFilter.replaceAll('+', '');
            phone = phone.replaceAll(' ', '');
            phone = phone.replaceAll('(', '');
            phone = phone.replaceAll(')', '');
            phone = phone.replaceAll('-', '');
            let dPhone: any = data?.phoneNumber;
            dPhone = dPhone.replaceAll(' ', '');
            dPhone = dPhone.replaceAll('(', '');
            dPhone = dPhone.replaceAll(')', '');
            dPhone = dPhone.replaceAll('-', '');
            if(dPhone?.toLowerCase().includes(phone?.toLowerCase())){
              return true;
            }
          }
          return false;
        }
        const searchText = filterOptions?.searchFilter?.toLowerCase().split(' ').filter((a: any) => a).join(' ');
        
        const fullName = data?.firstName?.toLowerCase() + ' ' + data?.lastName?.toLowerCase();
        if(
          (
            fullName.includes(searchText) ||
            data?.email?.toLowerCase().includes(filterOptions?.searchFilter?.toLowerCase()) ||
            isPhone()
          ) &&
          (
            (filterOptions.phaseId ? data?.phaseId === filterOptions.phaseId : true) &&
            pdate() &&
            (filterOptions.tags?.length ? filterOptions.tags.some((item: number) => data.tags.includes(item)) : true)
          )
          ){
          result.push(data)
        }
      })
      // console.log()
      // const newArr: IConversations[] = [];
      // conversationsBackend.forEach((conversationsArr) => {
      //   const newConversationArray: IConversationDto[] = [];
      //   conversationsArr.conversations.forEach((conversation) => {
      //     result.forEach((item: any) => {
      //       if (item.id === conversation.conversationName) {
      //         newConversationArray.push(conversation);
      //       }
      //     });
      //   });
      //   newArr.push({title: conversationsArr.title, conversations: newConversationArray});
      // });
      setUsers(result);
      // setFilteredConversations(newArr);
    }
    setSwitchLoading(false);
  };

  const handleChangeSearchValue = async (e: any) => {
    const searchValue = e.target.value;
    setFilters((prevState) => {
      return {
        ...prevState,
        searchCriteria: searchValue,
      };
    });

    if (searchValue?.length >= 3 || searchValue === '') {
      await handleFilter(searchValue);
    }
  };

  const handleClear = () => {
    initialUserTagsList.forEach((oneCategoryTag) => {
      oneCategoryTag.tags.forEach((oneTag) => {
        oneTag.isVisible = true;
      });
    });
    setTagsList(initialUserTagsList);
    tagsBoxRef?.current?.clearSelectedTags();
    setFilters((prevState) => {
      return {
        ...prevState,
        phaseObject: {
          key: 0,
          value: '',
        },
        date: null,
      };
    });
    handleFilter(filters.searchCriteria, secondaryTrainees, {
      phaseObject: {
        key: 0,
        value: '',
      },
      date: null,
      tags: [],
      trainerId: currentUser.id,
    });
  };

  const handleSwitch = (value: string) => {
    setSecondaryTrainees(value === 'Secondary');
    handleFilter('', value === 'Secondary');
  };

  const nodataAvailable = () => {
    let exist: any = false;
    filteredConversations.length > 0 &&
    filteredConversations.map((oneConversationGroup: any) => {
      if(oneConversationGroup?.conversations && oneConversationGroup?.conversations.length > 0)
      exist = true
    })
    if(!exist){
      return "No connection found"
    }
     return ''
  }
  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <ExpandableContainer
          className={
            props.selectedSideMenuItem === SideMenuSelectedType.SIDEMENU_CONNECTIONS ? 'active' : ''
          }
        >
          <Accordion defaultExpanded={
            selectedStateTags?.tags?.length ||
            selectedMemberFromRedux?.id ? true : false ||
            selectedStateTags?.phaseId ? true : false ||
            selectedStateTags?.phaseStartDate ? true : false
            }>
            <div
              onClick={() => {
                setConnectionsClicked(!connectionsClicked);
                props.onClick(
                  connectionsClicked
                    ? SideMenuSelectedType.SIDEMENU_NONE
                    : SideMenuSelectedType.SIDEMENU_CONNECTIONS,
                );
              }}
              onKeyDown={() => {
                setConnectionsClicked(!connectionsClicked);
                props.onClick(SideMenuSelectedType.SIDEMENU_CONNECTIONS);
              }}
              role="menuitem"
              tabIndex={-1}
            >
              <AccordionSummary expandIcon={<ExpandIcon clicked={connectionsClicked} />}>
                <SideMenuBarItem
                  Icon={ConnectionsIcon}
                  elementType={SideMenuSelectedType.SIDEMENU_CONNECTIONS}
                  onClick={(elementType: any) => props.onClick(elementType)}
                  text={t('Homepage.sideMenuBar.connections')}
                  selectedSideMenuItem={props.selectedSideMenuItem}
                />
              </AccordionSummary>
            </div>
            <div className={classes.connectionsContent}>
              <SearchBox
                className="side-menu-bar-input sidebar-searchBox"
                name="searchCriteria"
                placeholder={t('Homepage.sideMenuBar.connectionsDropDown.searchPlaceholder')}
                containerClass={classes.connectionsSearch}
                value={filters.searchCriteria}
                onChange={handleChangeSearchValue}
                autoComplete='off'
              />
              <ClientsSwitch
                className="sidebarSwitch"
                disabled={switchLoading || isLoadingRedux}
                title={t('Homepage.sideMenuBar.connectionsDropDown.clientsSwitchTitle')}
                label1={t('Homepage.sideMenuBar.connectionsDropDown.label1')}
                label2={t('Homepage.sideMenuBar.connectionsDropDown.label2')}
                setPrimary={(value: string) => handleSwitch(value)}
              />
              <FilterOptions
                filters={filters}
                handleChange={handleChange}
                handleFilter={handleFilter}
                handleClear={handleClear}
                tagsBoxRef={tagsBoxRef}
                tagsList={tagsList}
                changeSpecificDataType={changeSpecificDataType}
                phases={phases}
                selectedStateTags={selectedStateTags}
              />
            </div>
            <div className={classes.line} />
            {isLoadingRedux ? (
              <Loader />
            ) : (
              <AccordionDetails >
                {
                  //   users.length === 0 ? (
                  //   <div className={classes.noData}>
                  //     <Typography>{t('Homepage.sideMenuBar.connectionsDropDown.noData')}</Typography>
                  //   </div>
                  // ) : (
                  filteredConversations?.length > 0 &&
                    filteredConversations.map((oneConversationGroup) => {
                      return (
                        <ConnectionsGroup
                          key={oneConversationGroup.title}
                          connections={oneConversationGroup}
                          users={users}
                          title={oneConversationGroup.title}
                        />
                      );
                    })
                  //)
                }
                {
                   (!isLoadingRedux && !isLoading) && nodataAvailable()
                }
              </AccordionDetails>
            )}
          </Accordion>
        </ExpandableContainer>
      )}
    </Fragment>
  );
};
